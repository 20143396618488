import { createContext, useContext, useRef, type ReactNode } from "react";

import { useStore, type StoreApi } from "zustand";

import { AppStoreInitError } from "@repo/libs/errors/errors";
import { ApplicationConfiguration } from "@repo/libs/types/app";

import {
  AppStore,
  createAppStore,
  initAppStore,
} from "@website-toolkit/stores/createAppStore";

export const AppStoreContext = createContext<StoreApi<AppStore> | null>(null);

export interface AppStoreProviderProps {
  children: ReactNode;
  config: ApplicationConfiguration;
}

export function AppStoreProvider({ children, config }: AppStoreProviderProps) {
  const storeRef = useRef(createAppStore(initAppStore(config)));

  return (
    <AppStoreContext.Provider value={storeRef.current}>
      {children}
    </AppStoreContext.Provider>
  );
}

export const useAppStore = <T,>(selector: (store: AppStore) => T): T => {
  const appStoreContext = useContext(AppStoreContext);

  if (!appStoreContext) {
    throw new AppStoreInitError("Cannot Initialize App Store");
  }

  return useStore(appStoreContext, selector);
};
