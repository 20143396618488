import * as React from "react";

import { cn } from "../lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  theme?: "customer" | "rekaz";
}

const themeStyles = {
  customer:
    "border-theme-background-700 bg-theme-background placeholder:opacity-75 focus-visible:border-theme-primary",
  rekaz:
    "border-neutral-200 bg-white placeholder:text-neutral-500 focus-visible:border-neutral-950",
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, theme = "customer", ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border-2 px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
          theme === "customer" ? themeStyles.customer : themeStyles.rekaz,
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
