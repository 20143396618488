import { CheckCircle2 } from "lucide-react";
import {
  ControllerRenderProps,
  FieldValues,
  type Control,
} from "react-hook-form";

import { CustomField, CustomFieldType } from "@repo/libs/types/customFields";
import { useCurrentLocale, useI18n } from "@libs/providers/locales/client";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./form";
import { Input } from "./input";
import MapCustomField from "./map-custom-field";
import { RadioGroup } from "./radio-group";
import Radio from "./radio-icon";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";

type CustomFieldProps = {
  fieldOptions: CustomField;
  control: Control<FieldValues>;
};

export default function CustomFieldComponent({
  fieldOptions,
  control,
}: CustomFieldProps) {
  const locale = useCurrentLocale();
  const dir = locale === "ar" ? "rtl" : "ltr";
  return (
    <FormField
      control={control}
      name={fieldOptions.name}
      render={({ field }) => (
        <FormItem>
          <FormLabel required={fieldOptions.isRequired}>
            {fieldOptions.label}
          </FormLabel>
          <FormControl>
            {generateCustomField(fieldOptions, field, control, dir)}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

const generateCustomField = (
  field: CustomField,
  formField: ControllerRenderProps<FieldValues, string>,
  control: Control<FieldValues>,
  dir: "rtl" | "ltr",
) => {
  switch (field.type as CustomFieldType) {
    case CustomFieldType.String:
      return (
        <Input
          id={field.name}
          required={field.isRequired}
          placeholder={field.placeholder}
          {...formField}
        />
      );
    case CustomFieldType.Number:
      return (
        <Input
          type="number"
          id={field.name}
          required={field.isRequired}
          placeholder={field.placeholder}
          {...formField}
        />
      );
    case CustomFieldType.Date:
      return (
        <Input
          {...control.register(field.name)}
          placeholder={field.placeholder}
          type="date"
          {...formField}
        />
      );
    case CustomFieldType.List:
      return (
        <Select onValueChange={formField.onChange} dir={dir}>
          <SelectTrigger id={field.name}>
            <SelectValue placeholder={field.placeholder} />
          </SelectTrigger>
          <SelectContent>
            {field.options.map((option) => (
              <SelectItem key={option} value={option} dir={dir}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    case CustomFieldType.Maps:
      return <MapCustomField field={field} />;
    case CustomFieldType.Radio:
      // not tested
      return (
        <>
          <RadioGroup
            value={formField.value}
            onValueChange={formField.onChange}
          >
            {field.options.map((option) => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </RadioGroup>
          <small className="text-xs text-gray-300">{field.placeholder}</small>
        </>
      );
    default:
      return null;
  }
};

export const Addon = ({
  addon,
  formField,
}: {
  addon: CustomField;
  formField: ControllerRenderProps<FieldValues, string>;
}) => {
  const t = useI18n();

  return (
    <label className="relative block min-w-32">
      <input type="checkbox" className="peer hidden" {...formField} />
      <CheckCircle2
        size={18}
        className="peer-checked:text-theme-primary-500 absolute end-2 top-2 hidden peer-checked:block"
      />
      <div className="peer-checked:border-theme-primary-600 peer-focus:ring-theme-primary-500 group relative cursor-pointer rounded-lg border border-gray-300 p-4 focus:outline-none peer-focus:ring-2">
        <div className="flex flex-col justify-between gap-y-2">
          <p className="text-base font-medium text-gray-900">{addon.label}</p>
          <p className="text-theme-primary-500 font-medium text-gray-900">
            +{addon.amount} {t("sar")}
          </p>
        </div>
        <p className="mt-1 text-sm text-gray-500">{addon.placeholder}</p>
      </div>
    </label>
  );
};
