import { createStore } from "zustand/vanilla";

import { ApplicationConfiguration, PaymentMethod } from "@repo/libs/types/app";
import { hasApplePay } from "@repo/libs/utils";

interface State {
  configuration: ApplicationConfiguration;
  logoUrl: string;
  tosEnabled?: boolean;
  otpEnabled?: boolean;
  taxEnabled?: boolean;
  cartNotesEnabled?: boolean;
  taxRate: number;
  enabledPaymentMethods?: PaymentMethod[];
  disableCODForDeposit?: boolean;
  tosCheckboxEnabled?: boolean;
  customTosMessage?: string;
  reservationSuccessMessage?: string;
  moyasarPublicTokenKey?: string;
  separateFirstAndLastName?: boolean;
  compactProductCards?: boolean;
  disableMultiCart?: boolean;
  onlinePaymentProvider: "moyasar" | "myfatoorah";
  hideDuration?: boolean;
  hasPackages: boolean;
  hidePriceIfZero: boolean;
}

interface Actions { }

export const initAppStore = (
  configuration: ApplicationConfiguration,
): State => {
  // TODO: this should throw an error if configuration is not provided
  const { settings } = configuration;
  return {
    configuration,
    logoUrl: settings["Platform.Invoice.LogoUrl"],
    enabledPaymentMethods: getEnabledPaymentMethods(settings),
    disableCODForDeposit:
      settings["Platform.PaymentMethod.DisableCODForDeposit"]?.toLowerCase() ===
      "true",
    otpEnabled:
      settings[
        "Platform.Checkout.SendOtpToCustomerBeforeCheckout"
      ]?.toLowerCase() === "true",
    tosEnabled: settings["Platform.Checkout.HasTos"]?.toLowerCase() === "true",
    taxEnabled:
      settings["Platform.Invoice.TaxIsEnabled"]?.toLowerCase() === "true",
    taxRate: parseFloat(settings["Platform.Tax.Percentage"]) + 1 || 1,
    tosCheckboxEnabled:
      settings["Platform.Checkout.EnableTosCheckbox"]?.toLowerCase() === "true",
    customTosMessage: settings["Platform.Checkout.CustomTosMessage"],
    reservationSuccessMessage:
      settings["Platform.Reservation.CustomReservationSuccessMessage"],
    moyasarPublicTokenKey:
      settings["Platform.PaymentGateway.Moyasar.PublishableKey"],
    separateFirstAndLastName:
      settings["Platform.Checkout.SeparateFirstAndLastName"]?.toLowerCase() ===
      "true",
    compactProductCards:
      settings["Platform.Reservation.CompactProductCard"]?.toLowerCase() ===
      "true",
    cartNotesEnabled:
      settings["Platform.Checkout.CartNotesEnabled"]?.toLowerCase() === "true",
    disableMultiCart:
      settings["Platform.Checkout.DisableMultiCart"]?.toLowerCase() === "true",
    hideDuration:
      settings["Platform.Checkout.HideDuration"]?.toLowerCase() === "true",
    hasPackages:
      settings["Platform.Checkout.HasPackages"]?.toLowerCase() === "true",
    hidePriceIfZero:
      settings["Platform.Checkout.HidePriceIfZero"]?.toLowerCase() === "true",
    onlinePaymentProvider:
      (settings[
        "Platform.PaymentGateway.Provider"
      ] as State["onlinePaymentProvider"]) ?? "moyasar",
  };
};

export type AppStore = State & Actions;

export const defaultInitState: AppStore = {
  configuration: {} as ApplicationConfiguration,
  logoUrl: "",
  enabledPaymentMethods: [],
  disableCODForDeposit: false,
  otpEnabled: false,
  tosEnabled: false,
  tosCheckboxEnabled: false,
  taxEnabled: false,
  taxRate: 0,
  reservationSuccessMessage: "",
  moyasarPublicTokenKey: "",
  onlinePaymentProvider: "moyasar",
  hasPackages: false,
  hidePriceIfZero: false,
};

export const createAppStore = (initState: State = defaultInitState) => {
  return createStore<AppStore>()((set, get) => ({
    ...initState,
  }));
};

export const appStoreInstance = createAppStore(defaultInitState);

const getEnabledPaymentMethods = (settings: any) => {
  if (!settings) return [];

  const enabledPaymentMethods: PaymentMethod[] = [];
  if (settings["Platform.PaymentMethod.Mada"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.MADA);
  }
  if (settings["Platform.PaymentMethod.CreditCard"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.VISA);
  }
  if (settings["Platform.PaymentMethod.CashOnDelivery"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.CASH);
  }
  if (settings["Platform.PaymentMethod.ApplePay"] === "True" && hasApplePay()) {
    enabledPaymentMethods.push(PaymentMethod.APPLE_PAY);
  }
  if (settings["Platform.PaymentMethod.Tamara"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.TAMARA);
  }
  if (settings["Platform.PaymentMethod.BankTransfer"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.BANK_TRANSFER);
  }
  return enabledPaymentMethods;
};
