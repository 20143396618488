import React, { forwardRef } from "react";

import clsx from "clsx";

export interface AdvancedRadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  children: React.ReactNode;
}

const Radio = forwardRef<HTMLInputElement, AdvancedRadioProps>(
  ({ children, className, inputClassName, ...props }, ref) => (
    <label className="flex aspect-square items-center justify-center">
      <input
        type="radio"
        ref={ref}
        className={clsx("peer hidden", inputClassName)}
        {...props}
      />
      <span className={clsx("block", className)}>{children}</span>
    </label>
  ),
);

Radio.displayName = "Radio";
export default Radio;
