import { ErrorType } from "@libs/types/error";

// TODO: find a way to localize the error messages
export class AppError extends Error {
  constructor(
    public message: string,
    public code: string,
    public type: ErrorType,
    public details?: any,
  ) {
    super(message);
    this.name = this.constructor.name;
    const container = new Error(message);
    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, this.constructor)
    } else {
      Object.defineProperty(this, 'stack', {
        enumerable: false,
        value: container.stack,
        writable: true,
        configurable: true,
      })
    }
  }
}

export class NotFoundError extends AppError {
  constructor(localizedMessage: string, details?: any) {
    super(localizedMessage, "NOT_FOUND", ErrorType.NOT_FOUND, details);
  }
}

export class ValidationError extends AppError {
  constructor(localizedMessage: string, details?: any) {
    super(
      localizedMessage,
      "VALIDATION_ERROR",
      ErrorType.VALIDATION_ERROR,
      details,
    );
  }
}

export class AuthenticationError extends AppError {
  constructor(localizedMessage: string, details?: any) {
    super(
      localizedMessage,
      "AUTHENTICATION_ERROR",
      ErrorType.AUTHENTICATION_ERROR,
      details,
    );
  }
}

export class AppStoreInitError extends AppError {
  constructor(localizedMessage: string, details?: any) {
    super(
      localizedMessage,
      "APPSOTRE_INIT_ERROR",
      ErrorType.APPSOTRE_INIT_ERROR,
      details,
    );
  }
}

export class ApplicationError extends AppError {
  constructor(localizedMessage: string, details?: any) {
    super(
      localizedMessage,
      "APPLICATION_ERROR",
      ErrorType.APPLICATION_ERROR,
      details,
    );
  }
}

export class OnboardingError extends AppError {
  constructor(localizedMessage: string, details?: any) {
    super(localizedMessage, "ONBOARDING_ERROR", ErrorType.NOT_FOUND, details);
  }
}

export class TenantNotFoundError extends AppError {
  constructor(localizedMessage: string, details?: any) {
    super(
      localizedMessage,
      "TENANT_NOT_FOUND_ERROR",
      ErrorType.NOT_FOUND,
      details,
    );
  }
}
