"use client";

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  ControlPosition,
  Map,
  MapControl,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { Locate, LocateFixed } from "lucide-react";

import { useI18n } from "@repo/libs/providers/locales/client";
import {
  Coordinate,
  MapsCustomFieldOptions,
} from "@repo/libs/types/customFields";

import { MapPin } from "./icons";
import { useToast } from "./use-toast";

const MapsCustomField = ({
  pinPosition,
  setPinPosition,
  mapsOptions,
}: {
  pinPosition: Coordinate;
  setPinPosition: Dispatch<SetStateAction<Coordinate>>;
  mapsOptions?: MapsCustomFieldOptions;
}) => {
  const [zoom, setZoom] = useState(15);
  const [isPositionSet, setIsPositionSet] = useState(false);
  const { toast } = useToast();

  const map = useMap();
  useMapsLibrary("geometry");

  const t = useI18n();

  const setCurrentPosition = useCallback(
    (e?: React.MouseEvent) => {
      e?.preventDefault();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const newPosition = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setPinPosition(newPosition);
            if (map) {
              map.panTo(newPosition);
              map.setZoom(15);
            }
            setIsPositionSet(true);
          },
          (error) => {
            console.error(error);
            toast({
              title: t("location.geolocation.error"),
              description: t("location.geolocation.error.description"),
              variant: "destructive",
            });
          },
          {
            enableHighAccuracy: true,
          },
        );
      } else {
        toast({
          title: t("location.geolocation.error"),
          description: t("location.geolocation.error.description"),
          variant: "destructive",
        });
      }
    },
    [map],
  );

  const handleCenterChanged = useCallback(() => {
    if (map) {
      const newCenter = map.getCenter();
      if (newCenter) {
        setPinPosition({ lat: newCenter.lat(), lng: newCenter.lng() });
      }
    }
  }, [map]);

  useEffect(() => {
    if (!map || !mapsOptions || !mapsOptions.showBoundariesToCustomer) return;

    mapsOptions.polygons.forEach((polygonData) => {
      const polygon = new google.maps.Polygon({
        paths: polygonData.coordinates,
        strokeColor: "#115d33",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#4cbb17aa",
        fillOpacity: 0.35,
      });

      polygon.setMap(map);
    });
  }, [map]);

  return (
    <Map
      className="h-full w-full overflow-clip rounded-xl"
      defaultCenter={pinPosition}
      zoom={zoom}
      onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
      onCenterChanged={handleCenterChanged}
      gestureHandling={"greedy"}
      disableDefaultUI={true}
    >
      <MapControl position={ControlPosition.INLINE_END_BLOCK_END}>
        <div className="m-4 rounded-full bg-white p-4 shadow-xl">
          <button
            onClick={setCurrentPosition}
            className="flex items-center gap-2"
          >
            {isPositionSet ? (
              <LocateFixed className="text-blue-600" />
            ) : (
              <Locate />
            )}
          </button>
        </div>
      </MapControl>
      <CenteredMarker />
    </Map>
  );
};

const CenteredMarker = () => {
  return (
    <MapControl position={ControlPosition.CENTER}>
      <MapPin className="h-12 w-12" />
    </MapControl>
  );
};

export { MapsCustomField };
