export type HasCustomFields = {
  customFields: Array<CustomField>;
};

export type CustomFieldInput = Record<string, string | undefined>;

export type CustomField = {
  id: string;
  name: string;
  label: string;
  type: CustomFieldType;
  model: CustomFieldModel;
  placeholder?: string;
  isRequired: boolean;
  options: string[];
  showInCheckout: boolean;
  creationTime?: Date;
  creatorId?: string | undefined;
  lastModificationTime?: Date | undefined;
  lastModifierId?: string | undefined;
  amount?: number;
  mapsOptions?: MapsCustomFieldOptions;
};

export type ProductCustomField = CustomField & {
  productId: string;
};

export enum CustomFieldType {
  String = 1,
  Number = 2,
  Boolean = 3,
  Date = 4,
  List = 5,
  Maps = 6,
  AddOns = 7,
  Radio = 8,
}

export enum CustomFieldModel {
  Product = 1,
  Customer = 2,
  Reservation = 3,
  Subscription = 4,
  Invoice = 5,
}

export type MapsCustomFieldOptions = {
  polygons: MapCustomFieldPolygons[];
  showBoundariesToCustomer: boolean;
};

export type MapCustomFieldPolygons = {
  id: number;
  providerIds: string[];
  coordinates: Coordinate[];
};

export type Coordinate = {
  lat: number;
  lng: number;
};
