import { FieldValues, type Control } from "react-hook-form";

import { CustomField, CustomFieldType } from "@repo/libs/types/customFields";

import CustomFieldComponent, { Addon } from "../components/custom-field";
import { cn } from "../lib/utils";
import { FormControl, FormField, FormItem, FormMessage } from "./form";

type CustomFieldsGroupProps = {
  customFields: CustomField[] | undefined;
  control: Control<FieldValues>;
  className?: string;
};

export default function CustomFieldsGroup({
  customFields,
  control,
  className,
}: CustomFieldsGroupProps) {
  if (customFields?.length === 0) return null;

  return (
    <div className={cn("grid grid-cols-2 gap-4 md:col-span-1", className)}>
      {customFields &&
        customFields
          .filter((f) => f.type !== CustomFieldType.AddOns)
          .map((field, index) => (
            <div
              key={index}
              className={cn(
                index === customFields.length - 1 &&
                  index % 2 === 0 &&
                  "md:col-span-2",
                "col-span-2",
              )}
            >
              <CustomFieldComponent
                key={field.id}
                fieldOptions={field}
                control={control}
              />
            </div>
          ))}
    </div>
  );
}

export function AddonsGroup({
  customFields: addOns,
  control,
  className,
}: CustomFieldsGroupProps) {
  if (!addOns) return null;

  return (
    <div className={cn("grid grid-cols-2 gap-4 md:col-span-1", className)}>
      {addOns?.map((addon, index) => (
        <div key={index} className="md:col-span-1">
          <FormField
            control={control}
            name={addon.name}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Addon addon={addon} formField={field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ))}
    </div>
  );
}
