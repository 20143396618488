"use client";

import * as React from "react";

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";

import { cn } from "../lib/utils";

const radioThemeStyles = {
  customer: {
    base: "border-theme-background-700 text-theme-text ring-offset-theme-background",
    focus: "focus-visible:ring-theme-primary",
    checked: "text-theme-primary",
  },
  rekaz: {
    base: "border-neutral-200 text-neutral-900 ring-offset-white",
    focus: "focus-visible:ring-neutral-950",
    checked: "text-current",
  },
};

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & {
    theme?: "customer" | "rekaz";
  }
>(({ className, theme = "customer", ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    theme?: "customer" | "rekaz";
  }
>(({ className, theme = "customer", ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "aspect-square h-4 w-4 rounded-full border focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        radioThemeStyles[theme].base,
        radioThemeStyles[theme].focus,
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle
          className={cn(
            "h-2.5 w-2.5 fill-current",
            radioThemeStyles[theme].checked,
          )}
        />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
