import { z } from "zod";

import { CustomField, CustomFieldType } from "@libs/types/customFields";
import { safeParseDate } from "@libs/utils";

export const getCustomFieldSchemaByType = (
  customField: CustomField,
  errorMessage: string,
) => {
  switch (customField.type) {
    case CustomFieldType.String:
      return customField.isRequired
        ? z.string({ required_error: errorMessage }).min(1, errorMessage)
        : z.string().optional();

    case CustomFieldType.Number:
      return customField.isRequired
        ? z.coerce
          .number({
            required_error: errorMessage,
            invalid_type_error: errorMessage,
          })
          .min(1, errorMessage)
        : z.coerce.number({ invalid_type_error: errorMessage }).optional();

    case CustomFieldType.Boolean:
      return customField.isRequired
        ? z.coerce.boolean({ required_error: errorMessage })
        : z.coerce.boolean({ invalid_type_error: errorMessage }).optional();

    case CustomFieldType.Date:
      return customField.isRequired
        ? z.string({ required_error: errorMessage }).refine(
          (value) => {
            const date = safeParseDate(value, errorMessage);
            return date instanceof Date;
          },
          () => ({ message: errorMessage }),
        )
        : z
          .string({ required_error: errorMessage })
          .refine(
            (value) => {
              const date = safeParseDate(value, errorMessage);
              return date instanceof Date;
            },
            () => ({ message: errorMessage }),
          )
          .optional();

    case CustomFieldType.List:
      return customField.isRequired
        ? z.string({ required_error: errorMessage }).refine(
          (value) => customField.options.includes(value),
          () => ({ message: errorMessage }),
        )
        : z
          .string()
          .refine(
            (value) => value && customField.options.includes(value),
            () => ({ message: errorMessage }),
          )
          .optional();
    case CustomFieldType.Maps:
      return customField.isRequired
        ? z.string().refine(
          (value) => {
            const parts = value.split(",");
            return (
              parts.length === 2 &&
              !isNaN(Number(parts[0])) &&
              !isNaN(Number(parts[1]))
            );
          },
          () => ({ message: errorMessage }),
        )
        : z.string().optional();
    case CustomFieldType.AddOns:
      return z.boolean().optional();
    default:
      break;
  }
};

export const getCustomFieldsZodSchema = (
  customFields?: CustomField[],
  errorMessage?: string,
) => {
  if (!customFields || !customFields.length) return {};
  return (
    customFields.reduce(
      (acc, field) => {
        acc[field.name] = getCustomFieldSchemaByType(field, errorMessage!);
        return acc;
      },
      {} as Record<string, any>,
    ) || {}
  );
};

export const getCustomFieldsNames = (customFields?: CustomField[]) => {
  return customFields?.map((field) => field.name) || [];
};
