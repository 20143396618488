import { useState } from "react";

import { APIProvider } from "@vis.gl/react-google-maps";
import { MapPin, Pencil } from "lucide-react";
import { useFormContext } from "react-hook-form";

import { useCurrentLocale, useI18n } from "@repo/libs/providers/locales/client";
import {
  Coordinate,
  CustomField,
  MapsCustomFieldOptions,
} from "@repo/libs/types/customFields";

import { cn } from "../lib/utils";
import { Button } from "./button";
import { Input } from "./input";
import { MapsCustomField } from "./maps";
import { Sheet, SheetContent, SheetFooter, SheetHeader } from "./sheet";
import { useToast } from "./use-toast";

interface Position {
  lat: number;
  lng: number;
}
export default function MapCustomField({ field }: { field: CustomField }) {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isPositionSet, setIsPositionSet] = useState(false);
  const dir = useCurrentLocale() === "ar" ? "rtl" : "ltr";

  const [position, setPosition] = useState<Position>({
    lat: 24.710845,
    lng: 46.673693,
  });

  const { control, setValue, trigger } = useFormContext();
  const t = useI18n();
  const { toast } = useToast();

  const checkPoint = (
    latLng: Coordinate,
    savedPolygonsData?: MapsCustomFieldOptions,
  ) => {
    let pointInAnyPolygon = false;
    let polygonIndex = -1;

    if (!savedPolygonsData || savedPolygonsData.polygons.length === 0)
      return true;

    if (!google.maps.geometry) {
      setTimeout(() => checkPoint(latLng, savedPolygonsData), 500);
      return;
    }

    savedPolygonsData.polygons?.forEach((polygonData, index) => {
      const polygon = new google.maps.Polygon({
        paths: polygonData.coordinates,
      });

      if (google.maps.geometry?.poly.containsLocation(latLng, polygon)) {
        pointInAnyPolygon = true;
        polygonIndex = index;
      }
    });

    return pointInAnyPolygon;
  };

  const savePosition = () => {
    const isInPoint = checkPoint(position, field.mapsOptions);

    if (!isInPoint) {
      toast({
        title: t("location.outside.error"),
        description: t("location.outside.error.description"),
        variant: "destructive",

        duration: 1000,
      });
      return;
    }

    setValue(field.name, `${position.lat},${position.lng}`);
    trigger().then(() => {
      setIsPositionSet(true);
      setIsSheetOpen(false);
    });
  };

  const openSheet = () => {
    setIsSheetOpen(true);
  };

  return (
    <Sheet open={isSheetOpen} onOpenChange={() => setIsSheetOpen((s) => !s)}>
      <div className="flex gap-x-2">
        <Input
          value={isPositionSet ? t("location.selected") : t("location.select")}
          readOnly
          onClick={openSheet}
          className={cn(isPositionSet ? "text-green-700" : "text-gray-700")}
        />
        <Button type="button" className="shrink-0 gap-x-2" onClick={openSheet}>
          {isPositionSet ? (
            <>
              <Pencil size={16} />
            </>
          ) : (
            <>
              <MapPin size={16} />
            </>
          )}
        </Button>
      </div>
      <SheetContent
        dir={dir}
        onOpenAutoFocus={(e) => e.preventDefault()}
        side={"bottom"}
        className="flex h-[92.5svh] flex-col rounded-t-xl p-4 md:p-6"
      >
        <SheetHeader className="text-start sm:text-start">
          {t("location.set")}
        </SheetHeader>

        <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API || ""}>
          <MapsCustomField
            pinPosition={position}
            setPinPosition={setPosition}
            mapsOptions={field.mapsOptions}
          />
        </APIProvider>
        <input {...control.register(field.name)} type="hidden" />

        <SheetFooter className="w-full items-center justify-center pb-4">
          <div className="flex w-full flex-col items-center justify-center">
            <Button
              type="button"
              className="mt-4 w-full"
              onClick={savePosition}
            >
              {t("continue")}
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
